const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  showTagsView: (state) => state.app.showTagsView,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  userId: (state) => state.user.id,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  // 上传进度
  loaded: (state) => state.app.loaded,
  // 工具栏
  topbarRouters: (state) => state.permission.topbarRouters,
  defaultRoutes: (state) => state.permission.defaultRoutes,
  sidebarRouters: (state) => state.permission.sidebarRouters,
  // 数据字典
  // dict_datas: (state) => state.dict.dictDatas,

  // 表格识别
  needSave: (state) => state.table.needSave,
  sheetData: (state) => state.table.sheetData,
  sheetInfo: (state) => state.table.sheetInfo,
  subTaskId: (state) => state.table.subTaskId,
  tableOrder: (state) => state.table.tableOrder,

  showCompanyInfo: (state) => state.settings.showCompanyInfo,
}
export default getters
