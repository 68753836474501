<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {}
  },
  created() {
    // const isMobile = this.checkUserAgent()
    // console.log('isMobile :>> ', isMobile)
    // if (isMobile) {
    //   this.$router.push('/login/mobile')
    // }
  },
  mounted() {
    const isMobile = this.checkUserAgent()
    console.log('isMobile :>> ', isMobile)
    if (isMobile) {
      this.$router.push('/login/mobile')
    }
  },
  methods: {
    /**
     * 检查是否为手机客户端
     * @return {Boolean} 若是手机端返回true，反之为false
     */
    checkUserAgent() {
      return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
        navigator.userAgent
      )
    },
  },
}
</script>

<style scoped lang="stylus"></style>
