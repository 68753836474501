import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView/index.vue'
import LayoutContainer from '../layout'
Vue.use(VueRouter)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    // alias: 'home',
    redirect: { path: '/index' },
    component: LayoutContainer,
    children: [
      {
        path: 'index',
        component: () => import('../views/HomeView/index.vue'),
        name: '首页',
        meta: { title: '首页', icon: 'home3', affix: true },
      },
    ],
  },
  // {
  //   path: '/',
  //   // alias: 'home',
  //   component: LayoutContainer,
  //   children: [
  //     {
  //       path: 'news',
  //       component: () => import('../views/NewsView/index.vue'),
  //       name: '舆情资讯分析',
  //       meta: { title: '舆情资讯分析', icon: 'new3', affix: true },
  //     },
  //   ],
  // },
  // {
  //   path: '/',
  //   component: LayoutContainer,
  //   children: [
  //     {
  //       path: 'contractComparison',
  //       component: () => import('../views/ContractView/index.vue'),
  //       name: '合同比对',
  //       meta: { title: '合同比对', icon: 'contract', affix: true },
  //     },
  //   ],
  // },

  // {
  //   path: '/',
  //   component: LayoutContainer,
  //   children: [
  //     {
  //       path: 'idcard',
  //       component: () => import('../views/IDcardView/index.vue'),
  //       name: '身份证识别',
  //       meta: { title: '身份证识别', icon: 'IDcard3', affix: true },
  //     },
  //   ],
  // },
  // {
  //   path: '/',
  //   component: LayoutContainer,
  //   children: [
  //     {
  //       path: 'busi_lic',
  //       component: () => import('../views/BusinessView/index.vue'),
  //       name: '营业执照识别',
  //       meta: { title: '营业执照识别', icon: 'Bus3', affix: true },
  //     },
  //   ],
  // },
  // {
  //   path: '/',
  //   component: LayoutContainer,
  //   children: [
  //     {
  //       path: 'pdf',
  //       component: () => import('../views/PDFView/index.vue'),
  //       name: 'PDF识别',
  //       meta: { title: 'PDF识别', icon: 'PDF3', affix: true },
  //     },
  //   ],
  // },
  {
    path: '/',
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'letter',
        component: () => import('../views/LetterView/index.vue'),
        name: '信息通知',
        meta: { title: '信息通知', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/',
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'message',
        component: () => import('../views/MessageView/index.vue'),
        name: '在线留言',
        meta: { title: '在线留言', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/',
    // alias: 'home',
    // redirect: { path: '/message' },
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'user',
        component: () => import('../views/UserView/index.vue'),
        name: '用户中心',
        meta: { title: '用户中心', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/',
    // alias: 'home',
    // redirect: { path: '/message' },
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'store',
        component: () => import('../views/StoreView/index.vue'),
        name: '商城',
        meta: { title: '商城', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  // {
  //   path: '/',
  //   // alias: 'home',
  //   // redirect: { path: '/message' },
  //   component: LayoutContainer,
  //   hidden: true,
  //   children: [
  //     {
  //       path: 'oauth',
  //       component: () => import('../views/OAuthView/index.vue'),
  //       name: '一键登录',
  //       meta: { title: '一键登录', icon: 'security', affix: true },
  //       hidden: false,
  //     },
  //   ],
  // },
  {
    path: '/',
    // alias: 'home',
    // redirect: { path: '/message' },
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'account',
        component: () => import('../views/AccountView/index.vue'),
        name: '我的账户',
        meta: { title: '我的账户', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/',
    // alias: 'home',
    // redirect: { path: '/message' },
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'guide',
        component: () => import('../views/GuideView/index.vue'),
        name: '帮助文档',
        meta: { title: '帮助文档', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/',
    // alias: 'home',
    // redirect: { path: '/message' },
    component: LayoutContainer,
    hidden: true,
    children: [
      {
        path: 'search',
        component: () => import('../views/SearchView/index.vue'),
        name: '全局搜索',
        meta: { title: '全局搜索', icon: 'dashboard', affix: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    hidden: true,
    children: [
      {
        path: '',
        // alias: '',
        component: () => import('../views/LoginView/CustomLogin.vue'),
        hidden: true,
      },
    ],
  },
  {
    path: '/wechat/callback',
    name: 'callback',
    component: () => import('../views/LoginView/WechatLogin.vue'),
    hidden: true,
  },
]

const router = new VueRouter({
  routes: constantRoutes,
  mode: 'history',
})

export default router
