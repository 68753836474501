<template>
  <div class="head-zone">
    <div class="left">
      <div class="logo" v-if="showCompanyInfo"></div>
      <div class="company-name" v-if="showCompanyInfo">
        <div class="title-name">{{ companyName }}</div>
        <div class="subtitle-name">{{ companySubname }}</div>
      </div>
      <div class="company-name" v-else>
        <div class="title-name" style="width: 130px">AI 云平台</div>
        <div class="subtitle-name" style="width: 130px">AI CLOUD</div>
      </div>
    </div>
    <div class="right">
      <div class="tool-wrapper">
        <span class="user_info">欢迎您：{{ userInfo.name }}</span>
        <el-input size="mini" placeholder="全站搜索" clearable v-model="keyword" @keyup.enter.native="SearchBtn">
          ></el-input
        >
        <el-button size="mini" icon="el-icon-search" @click="SearchBtn"></el-button>

        <el-tooltip v-for="(item, index) in tools" :key="index" effect="dark" :content="item.name" placement="bottom">
          <div class="tool-item" :style="{ 'background-image': `url(${item.url})` }" @click="toolSelect(item)">
            <div class="message-count" v-show="item.name == '通知' && messageCount && messageCount < 10">
              {{ messageCount }}
            </div>
            <div class="message-count" v-show="item.name == '通知' && messageCount > 9">
              <span style="display: inline-block; transform: scale(0.9) translate(0.5px, 1px)">9</span>
              <span style="display: inline-block; transform: scale(1.2) translate(0px, 3px)">⁺</span>
            </div>
          </div>
        </el-tooltip>
      </div>
      <el-dropdown @command="userHandle" size="medium">
        <!-- <div class="user-img" :style="{ 'background-image': `url(${imgUrl}` }"> -->
        <div class="user-img">
          <AvatarView v-if="userInfo.account" :size="33" :name="userInfo.account" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="userCenter">
            <svg-icon icon-class="userLight" style="font-size: 14px" /> 用户中心
          </el-dropdown-item>
          <el-dropdown-item command="account">
            <svg-icon icon-class="wallet2" style="font-size: 14px" /> 我的账户
          </el-dropdown-item>
          <el-dropdown-item command="store">
            <svg-icon icon-class="shopping-cart" style="font-size: 14px" /> 商城
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <svg-icon icon-class="logout" style="font-size: 14px" /> 退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
      title="界面设置"
      :visible.sync="drawer"
      direction="rtl"
      :append-to-body="true"
      :before-close="drawerClose"
      size="300px"
    >
      <div class="setting-wrapper">
        <div class="setting-block">
          <h5 class="setting-title" data-v-d3490518="">内容区域</h5>
          <div class="setting-item">
            <span>当前路径</span>
            <el-switch v-model="showTagsView" active-color="#165dff" inactive-color="#c9cdd4"> </el-switch>
          </div>
          <div class="setting-item">
            <span>导航菜单</span>
            <el-switch v-model="expandMenu" active-color="#165dff" inactive-color="#c9cdd4"> </el-switch>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="centerDialogVisible"
      custom-class="my-dialog"
      top="64px"
      :modal="false"
      width="300px"
      :show-close="false"
      center
    >
      <div class="message-head">
        <div class="items">
          <div class="head-item" :class="{ activate: headSelect == 1 }" @click="headSelect = 1">
            消息({{ messageNum }})
          </div>
          <div class="head-item" :class="{ activate: headSelect == 2 }" @click="headSelect = 2">
            通知({{ letterNum }})
          </div>
        </div>
        <div class="head-button" @click="emptyAllInfo">清空</div>
      </div>
      <div class="message-content">
        <div class="content" v-if="headSelect == 1">
          <div v-if="messageList.length">
            <div class="message-item" v-for="(item, index) in messageList" :key="index + 'message'">
              <div class="title-box">
                <div class="topic">{{ item.topic }}</div>
                <div class="type" :class="getClassName(item.type)">{{ formatterType(item.type) }}</div>
              </div>
              <div class="info">{{ item.reply }}</div>
              <div class="time">{{ parseTime(item.replyTime) }}</div>
            </div>
          </div>
          <div class="empty" v-else>暂无新消息</div>
        </div>
        <div class="content" v-else>
          <div v-if="letter.length">
            <div v-for="(item, index) in letter" :key="index + 'letter'">
              <div class="title-box">
                <div class="topic">{{ item.topic }}</div>
                <!-- <div class="type" :class="getClassName(item.type)">{{ formatterType(item.type) }}</div> -->
              </div>
              <div class="info">{{ item.contents }}</div>
              <div class="time">{{ parseTime(item.sendTime) }}</div>
            </div>
          </div>
          <div class="empty" v-else>暂无新通知</div>
        </div>
      </div>
      <div class="message-foot">
        <div class="foot-item" style="border-right: 1px solid rgb(242, 243, 245)" @click="MarkRead">全部已读</div>
        <div class="foot-item" @click="moreInfo">更多</div>
      </div>
    </el-dialog>
    <el-drawer
      title="全站搜索"
      :visible.sync="drawer1"
      direction="rtl"
      :append-to-body="true"
      :before-close="drawer1Close"
      size="580px"
    >
      <SearchView @show="show" :keyword="keyword" @update="update"></SearchView>
    </el-drawer>
  </div>
</template>

<script>
import tool1 from '@/assets/images/head/Frame.svg'
import tool2 from '@/assets/images/head/notification.svg'
import tool3 from '@/assets/images/head/help2.png'
import tool6 from '@/assets/images/head/search.png'
import tool4 from '@/assets/images/head/Group4.png'
import tool5 from '@/assets/images/head/Group5.png'
import AvatarView from '@/components/avatar/index.vue'
import SearchView from '@/components/search/index.vue'
import path from 'path'
import { listMessage, deleteMessage, saveMessage, readMessage } from '@/api/message'
import { queryCustomer, editCustomer, editPassword, genSecretKey } from '@/api/personal'
import { listLetter, readLetter, deleteLetter } from '@/api/letter'
import { parseTime } from '@/utils/common'
export default {
  name: 'TopNav',
  components: {
    AvatarView,
    SearchView,
  },
  data() {
    return {
      keyword: '',
      parseTime,
      userInfo: '',
      companyName: '智能思创智能云',
      companySubname: 'AISTRONG AI CLOUD',
      imgUrl: '',
      headSelect: 1,
      tools: [
        // {
        //   id: 3,
        //   name: '搜索',
        //   url: tool6,
        // },
        {
          id: 0,
          name: '通知',
          url: tool2,
        },
        {
          id: 2,
          name: '帮助',
          url: tool3,
        },
        {
          id: 1,
          name: '设置',
          url: tool1,
        },
      ],
      user: '',
      drawer: false,
      drawer1: false,
      showTagsView: this.$store.state.app.showTagsView,
      expandMenu: this.$store.state.app.sidebar.opened,
      centerDialogVisible: false,
      letter: [
        {
          topic: '余额不足',
          type: '1',
          contents: '您的余额已不足10元，请尽快充值',
          time: 12312,
        },
        {
          topic: '余额不足',
          type: '1',
          contents: '您的余额已不足10元，请尽快充值',
          time: 12312,
        },
      ],
      messageList: [],
    }
  },
  computed: {
    avatar() {
      return this.loadFiles()
    },
    messageCount() {
      let messageNum = this.$store.state.user.messageCount
      let letterNum = this.$store.state.user.letterCount
      return messageNum + letterNum
    },
    showCompanyInfo() {
      return this.$store.state.settings.showCompanyInfo
    },
    messageNum() {
      return this.$store.state.user.messageCount
    },
    letterNum() {
      return this.$store.state.user.letterCount
    },
  },
  watch: {
    centerDialogVisible(value) {
      if (value) {
        this.getInfo()
      }
    },
    showTagsView(value, oldValue) {
      if (value) {
        this.$store.dispatch('app/showTagsView')
      } else {
        this.$store.dispatch('app/closeTagsView')
      }
    },
    expandMenu(value, oldValue) {
      if (value) {
        this.$store.dispatch('app/toggleSideBar')
      } else {
        this.$store.dispatch('app/closeSideBar', false)
      }
    },
  },
  beforeMount() {
    this.getUserInfo()
  },
  mounted() {
    this.imgUrl = this.getRandom(this.loadFiles())
    this.$store.dispatch('app/setAvatar', this.imgUrl)
    this.getInfo()

    console.log('this.$store.state.showCompanyInfo :>> ', this.$store.state.showCompanyInfo)
  },

  methods: {
    SearchBtn() {
      this.drawer1 = true
      this.$root.$emit('keyword', this.keyword)
      // setTimeout((this.keyword = ''), 5000)
    },
    update(value) {
      this.keyword = value
    },
    show(value) {
      this.drawer1 = value
    },
    getUserInfo() {
      queryCustomer().then((res) => {
        this.userInfo = res.data
      })
    },
    MarkRead() {
      if (this.headSelect == 1) {
        // 消息
        this.messageList.forEach((item) => {
          readMessage(item.id).then((res) => {})
        })
        // this.$store.dispatch('SetMessageCount', 0)
        this.getInfo()
      } else {
        // 通知
        readLetter({ ids: 0 }).then((res) => {})
        // this.$store.dispatch('SetLetterCount', 0)
        this.getInfo()
      }
    },
    emptyAllInfo() {
      if (this.headSelect == 1) {
        // 消息
        this.messageList.forEach((item) => {
          deleteMessage({ id: item.id }).then((res) => {})
        })
        // this.$store.dispatch('SetMessageCount', 0)
        this.getInfo()
      } else {
        // 通知
        this.letter.forEach((item) => {
          deleteLetter({ ids: item.id }).then((res) => {})
        })

        // this.$store.dispatch('SetLetterCount', 0)
        this.getInfo()
      }
    },
    getInfo() {
      listMessage({ page: 1, size: 99 }).then((res) => {
        let list = res.data.rows.filter((item) => {
          if (item.reply && item.readStatus == 0) return true
        })

        if (list.length > 3) {
          this.messageList = list.slice(0, 3)
        } else if (list[0]) {
          this.messageList = list
        }
        this.$store.dispatch('SetMessageCount', list[0] ? list.length : 0)
      })

      listLetter({ readStatus: 0, page: 1, size: 99 }).then((res) => {
        this.letter = res.data.rows
        this.$store.dispatch('SetLetterCount', res.data.total)
      })
    },
    moreInfo() {
      if (this.headSelect == 1) {
        this.$router.push({ path: '/message' })
      } else {
        this.$router.push({ path: '/letter' })
      }
      this.centerDialogVisible = false
    },
    loadFiles() {
      const files = require.context(`/src/assets/images/user`, false, /\.(png|jpg|jpeg|svg)$/)
      let list = []
      files.keys().forEach((key) => {
        const name = path.basename(key)
        const url = require(`/src/assets/images/user/${name}`)
        list.push(url)
      })
      //
      return list
    },
    getRandom(max, min = 0) {
      if (max instanceof Array) {
        return max[this.getRandom(max.length)]
      } else {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min) + min)
      }
    },
    formatterType(type, column) {
      switch (type) {
        case 1:
          type = '咨询'
          break
        case 2:
          type = '建议'
          break

        default:
          type = '投诉'
          break
      }
      return type
    },
    getClassName(type) {
      let claseName
      switch (type) {
        case 1:
          claseName = 'type1'
          break
        case 2:
          claseName = 'type2'
          break
        default:
          claseName = 'type3'
          break
      }
      return claseName
    },
    toolSelect(item) {
      switch (item.id) {
        case 1:
          this.drawer = true
          break
        case 2:
          this.$router.push({ path: '/guide' })
          break
        case 3:
          this.SearchBtn()
          // this.$router.push({ path: '/search' })
          break
        default:
          this.centerDialogVisible = true
          break
      }
    },
    userHandle(command) {
      if (command === 'logout') {
        this.$confirm('您确定退出该系统吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$store.dispatch('LogOut').then(() => {
              this.$notify({
                title: '提示',
                message: '安全退出登录',
                type: 'success',
              })
              this.$router.push({ path: '/login' })
            })
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // })
          })
      } else if (command === 'userCenter') {
        this.$router.push({ path: '/user' })
      } else if (command === 'account') {
        this.$router.push({ path: '/account' })
      } else if (command === 'store') {
        this.$router.push({ path: '/store' })
      }
    },
    drawerClose() {
      this.drawer = false
    },
    drawer1Close() {
      this.drawer1 = false
    },
  },
}
</script>

<style scoped lang="stylus">
/deep/ .my-dialog .el-dialog__header
  display none !important

/deep/ .my-dialog .el-dialog__body
  padding 0 !important

/deep/ .my-dialog
  margin 0 !important
  position absolute
  top 64px
  right 25px

.empty
  text-align center
  height 100px
  line-height 100px
  user-select none
  color $color-text-4
.message-content
  height 160px
  padding 0 16px
  .content
    height 160px
    overflow auto
    .message-item
      border-bottom 1px solid $color-border-3
    .title-box
      padding 5px 0
      display flex
      flex-direction row
      justify-content space-between
      align-items center
      .topic
        font-weight 700
      .type
        align-items center
        padding 1px 8px
        border-radius 2px
    .info
      padding-bottom 5px
    .time
      padding-bottom 5px
      color $color-text-4
      font-size $font-size-body-1
.message-foot
  display flex
  flex-direction row
  justify-content flex-start
  align-items center
  border-top 1px solid $color-border-2
  .foot-item
    width calc(50% - 0.5px)
    padding 10px 16px
    text-align center
    cursor pointer
    color $primary-6
.activate
  background-color #F2F3F8
  color $primary-6
.type1
  color $success-6
  background-color $success-2
.type2
  color $primary-6
  background-color $primary-2
.type3
  color $danger-6
  background-color $danger-2

.message-head
  padding 7px 16px
  display flex
  flex-direction row
  justify-content space-between
  align-items center
  border-bottom 1px solid $color-border-2
  .items
    display flex
    flex-direction row
    justify-content flex-start
    align-items center
    // .head-item:hover
    //   background-color #F2F3F8
    //   color $primary-6
    //   cursor pointer
    .head-item
      padding 5px 16px
      border-radius 100px
      width 70px
      height 24px
      line-height 24px
      text-align center
      padding 2px 5px
      cursor pointer
  .head-button
    color $primary-6
    padding 5px 10px
    border-radius 4px
  .head-button:hover
    cursor pointer
    background-color $primary-1
.head-zone
  height 60px
  display flex
  position relative
  flex-direction row
  justify-content space-between
  align-items center
  border-bottom 0.5px solid $color-border-2
  .left
    display flex
    flex-direction row
    justify-content flex-start
    align-items center
    padding-left 20px
    cursor pointer
    .logo
      height 40px
      width 40px
      background-image url("~@/assets/images/logo.png")
      background-size 100%
      background-repeat no-repeat
      background-position center
      margin-right 10px
    .company-name
      // font-family 'MiSans-Heavy'
      .title-name
        font-family 'MiSans-Normal'
        // font-family 'MiSans-Heavy'
        text-align justify
        text-justify distribute-all-lines
        text-align-last justify
        font-weight 600
        color $color-text-2
        width 165px
        font-size $font-size-title-2
        border-bottom 1px solid $color-border-5
      .subtitle-name
        font-size $font-size-body-3
        color $color-text-2
        letter-spacing 1px
        // font-family 'MiSans-Heavy'
        text-align justify
        text-justify distribute-all-lines
        text-align-last justify
        width 165px
  .right
    height 50px
    width 700px
    padding-right 10px
    display flex
    flex-direction row
    justify-content flex-end
    align-items center
    .tool-wrapper
      z-index 2
      margin-right -20px
      display flex
      flex-direction row
      justify-content flex-end
      align-items center
      width 100%
    .user-img
      z-index 1
      margin-left 20px
      position relative
      height 31px
      width 31px
      border-radius 50%
      background-size 100%
      background-repeat no-repeat
      background-position 49% 49%
      border 1px solid $color-border-2
      margin 0 40px !important
      overflow hidden
    .user-img:hover
      cursor pointer
    .tool-item:hover
      cursor pointer
      background-color #F8F8F8
    .tool-item
      margin-left 20px
      position relative
      height 31px
      width 31px
      border-radius 50%
      background-size 50%
      background-repeat no-repeat
      background-color #FAFAFA
      background-position 49% 49%
      border 1px solid $color-border-3
      .message-count
        height 14px
        width 14px
        line-height 14px
        font-size 12px
        font-family MiSans-Normal
        text-align center
        border-radius 50%
        background-color $danger-6
        position absolute
        right -4px
        color white
/deep/ .el-drawer__header
  height 48px !important
  margin 0 !important
  font-size $font-size-title-1
  color $color-text-1
  padding 0px 16px
  border-bottom: 1px solid $color-border-3

.setting-wrapper
  padding 12px 16px
  .setting-title
    margin 10px 0
    font-size $font-size-body-3
  .setting-item
    height 32px
    line-height 32px
    font-size $font-size-body-3
    display flex
    flex-direction row
    justify-content space-between
    align-items center
.user_info
  padding 5px 10px
  color $color-text-3
  background $gray-light-3
  border-radius 5px
  border 1px solid $color-border-1
// 搜索框
.el-input
   width 160px
   margin-left  20px
.el-button--mini
  padding-left 12px
  padding-right 12px
  margin-left -1px
  border-radius 0 4px 4px 0
/deep/ .el-input__inner
  border-radius 4px 0 0 4px
</style>
