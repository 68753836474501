import request from '@/utils/request'
import qs from 'qs'

// 获取站内信列表
export function listLetter({ readStatus, page, size }) {
  let data = {
    readStatus,
    page,
    size,
  }
  return request({
    url: '/ais/letter/list_letter',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 阅读站内信接口
export function readLetter({ ids }) {
  let data = {
    ids,
  }
  return request({
    url: '/ais/letter/read_letter',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 阅读站内信接口
export function deleteLetter({ ids }) {
  let data = {
    ids,
  }
  return request({
    url: '/ais/letter/delete_letter',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}
/**
 * 批量删除
 * @param {Array} ids 需要删除的任务id
 * @returns
 */
export function batchDelete({ ids }) {
  let data = {
    ids: JSON.stringify(ids),
  }
  return request({
    url: '/ais/letter/delete_letter',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}
