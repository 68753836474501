<template>
  <div class="container">
    <div class="table-container">
      <div class="content" id="content" ref="content">
        <div class="main">
          <div class="search">
            <div class="search-item item-1">
              <!-- <span class="label">关键词</span> -->
              <el-input
                class="input"
                placeholder="请输入关键词"
                clearable
                v-model="search.keyword"
                @input="change"
                @keyup.enter.native="SearchBtn"
              ></el-input>
            </div>
            <div class="search-item">
              <el-button type="primary" icon="el-icon-search" @click="SearchBtn">搜索</el-button>
            </div>
          </div>
          <div class="search-wrapper">
            <el-collapse @change="showSelect">
              <el-collapse-item title="条件筛选"><div style="height: 0"></div></el-collapse-item></el-collapse
            ><el-dialog
              class="select"
              :visible.sync="centerDialogVisible"
              custom-class="dialog"
              :modal="false"
              width="540px"
              :show-close="false"
              center
            >
              <div class="select-item">
                <label>关键词：</label>
                <el-checkbox v-model="searchSeal">精确查找</el-checkbox>
              </div>
              <div class="select-item">
                <label for="pType">产品类型：</label>
                <el-checkbox v-model="allType" @change="typeCheckChange" :indeterminate="isIndeterminate1"
                  >全选</el-checkbox
                >
                <el-checkbox-group v-model="checkTypeList" @change="typeChange" id="pType">
                  <el-checkbox v-for="item in typeList" :key="item.value" :label="item.value.toString()">{{
                    item.label
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="select-item">
                <label for="status">任务状态：</label>
                <el-checkbox v-model="allStatus" @change="statusCheckChange" :indeterminate="isIndeterminate2"
                  >全选</el-checkbox
                >
                <el-checkbox-group v-model="checkStatusList" @change="statusChange" id="status">
                  <el-checkbox v-for="item in options1" :key="item.value" :label="item.value.toString()">{{
                    item.label
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="select-item">
                <label>按创建时间排序：</label>
                <el-radio label="1" v-model="searchPsort">降序排列</el-radio>
                <el-radio label="2" v-model="searchPsort">升序排列</el-radio>
              </div>
            </el-dialog>
          </div>
          <div class="table-wrapper" id="tableWrapper" ref="tableWrapper">
            <div class="table" v-loading="showLoading">
              <ul v-if="rows.length != 0" :style="{ height: `${tableHeight}px` }" id="searchbox">
                <li class="searchbox" v-for="row in rows" :key="row.id">
                  <div>
                    <span class="taskName" @click="handleClick(row)" v-html="row.taskName">{{ row.taskName }}</span>
                  </div>
                  <div class="body">
                    <span v-html="row.content" class="res contenter" @click="handleClick(row)">{{ row.content }}</span>
                    <span class="fileName" v-html="row.fileName">{{ row.fileName }}</span>
                  </div>
                  <div class="date">
                    <span>【{{ formatterProductCode(row) }}】</span>{{ formatterTime(row) }}
                  </div>
                </li>
              </ul>
              <ul v-else style="text-align: center; line-height: 60px; width: 100%; color: #909399; margin: 0">
                暂无内容
              </ul>
            </div>

            <!-- 分页 -->
            <div class="pagination-wrapper">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[10, 20, 100, 200]"
                :page-size="pageSize"
                :pager-count="5"
                layout="total, prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchAns } from '@/api/search'
import { parseTime } from '@/utils/common'
export default {
  name: 'SearchView',
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      product: '',
      rows: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      showLoading: false,
      options1: [
        {
          value: 1,
          label: '未启动',
          // disabled: true,
        },
        {
          value: 2,
          label: '正在分解',
        },
        {
          value: 4,
          label: '正在识别',
        },
        {
          value: 97,
          label: '已停止',
        },
        {
          value: 98,
          label: '执行完成（有异常）',
        },
        {
          value: 99,
          label: '执行完成',
        },
      ],
      options2: [
        {
          value: 1,
          label: '表格识别',
        },
        // {
        //   value: 2,
        //   label: '文字识别',
        // },
        // {
        //   value: 3,
        //   label: '营业执照识别',
        // },
        // {
        //   value: 4,
        //   label: '身份证识别',
        // },
        {
          value: 5,
          label: 'PDF识别',
        },
        {
          value: 6,
          label: '文本比对',
        },
        {
          value: 7,
          label: '舆情资讯分析',
        },
      ],
      typeList: [],
      allType: true,
      allStatus: true,
      isIndeterminate1: false,
      isIndeterminate2: false,
      searchSeal: true,
      checkTypeList: [],
      checkStatusList: ['1', '2', '4', '97', '98', '99'], //'1', '2', '4', '97', '98', '99'
      searchPsort: '1',
      tableHeight: 400,
      checked: false,
      stopRefresh: false,
      search: {
        keyword: '',
        productCode: '',
        status: '',
        psort: '1',
        seal: 1,
        page: 1,
        size: 10,
      },
    }
  },
  watch: {
    checkStatusList(value) {
      this.search.status = value.toString()
      this.search.page = 1
      this.currentPage = 1
      this.getTableInfo()
    },
    checkTypeList(value) {
      this.search.productCode = value.toString()
      this.search.page = 1
      this.currentPage = 1
      this.getTableInfo()
    },
    searchPsort(value) {
      this.search.psort = value
      this.search.page = 1
      this.currentPage = 1
      this.getTableInfo()
    },
    searchSeal(value) {
      if (value) this.search.seal = 1
      else this.search.seal = 2
      this.search.page = 1
      this.currentPage = 1
      this.getTableInfo()
    },
  },
  created() {
    let menus = this.$store.state.permission.addRoutes
    if (menus)
      for (let item of menus) {
        for (let e of this.options2) {
          if (item.children)
            if (item.children[0].name === e.label) {
              this.typeList.push(e)
              this.checkTypeList.push(e.value.toString())
            }
        }
      }
    this.search.status = this.checkStatusList.toString()
    // this.SearchBtn()
    // if (!keyword || keyword === '') {
    //   this.newList()
    // } else {
    //   this.SearchBtn()
    // }
  },
  mounted() {
    this.search.keyword = this.keyword
    const _this = this
    this.$root.$on('keyword', (data) => {
      _this.search.keyword = data
      _this.SearchBtn()
      // }
    })

    this.resizeObserver = new ResizeObserver((entries) => {
      this.tableHeight = entries[0].target.offsetHeight - 65
    })
    // 绑定
    this.$nextTick(() => {
      this.resizeObserver.observe(document.getElementById('content'))
    })
  },
  destroyed() {
    this.resizeObserver.disconnect()
    this.stopRefresh = true
  },
  methods: {
    // newList() {
    //   // 开始默认显示最新的10条
    //   searchAns(this.search)
    //     .then((res) => {
    //       let data = res.data
    //       this.rows = data.rows.slice(0, 10)
    //       // console.log('this.data:>>', this.rows)
    //       this.total = this.rows.length
    //       this.showLoading = false
    //     })
    //     .catch((error) => {
    //       this.showLoading = false
    //     })
    // },
    showSelect() {
      this.centerDialogVisible = true
      // console.log('centerDialogVisible:>>', this.centerDialogVisible)
    },
    change() {
      this.$emit('update', this.search.keyword)
    },
    typeCheckChange(val) {
      //val就是v-model绑定的值
      if (val) {
        this.typeList.forEach((item) => {
          this.checkTypeList.push(item.value.toString())
        })
      } else {
        this.checkTypeList = []
      }
      this.isIndeterminate1 = false
    },
    typeChange(value) {
      //value就是v-model绑定的值
      this.allType = value.length === this.typeList.length
      this.isIndeterminate1 = value.length > 0 && value.length < this.typeList.length //同全选按钮事件里面的那个样式控制
    },
    statusCheckChange(val) {
      //val就是v-model绑定的值
      if (val) {
        this.options1.forEach((item) => {
          this.checkStatusList.push(item.value.toString())
        })
      } else {
        this.checkStatusList = []
      }
      this.isIndeterminate2 = false
    },
    statusChange(value) {
      //value就是v-model绑定的值
      this.allStatus = value.length === this.options1.length
      this.isIndeterminate2 = value.length > 0 && value.length < this.options1.length //同全选按钮事件里面的那个样式控制
    },
    drawerClose() {
      this.drawer = false
    },
    formatterProductCode(row) {
      let product = +row.productCode
      switch (product) {
        case 1:
          product = '表格识别'
          break
        case 2:
          product = '文字识别'
          break
        case 3:
          product = '营业执照识别'
          break
        case 4:
          product = '身份证识别'
          break
        case 5:
          product = 'PDF识别'
          break
        case 6:
          product = '文本对比'
          break
        default:
          product = '舆情资讯分析'
          break
      }
      return product
    },
    formatterStatus(row) {
      let status = +row.status
      switch (status) {
        case 1:
          status = '未启动'
          break

        case 2:
          status = '正在分解'
          break

        case 4:
          status = '正在识别'
          break

        case 97:
          status = '已停止'
          break

        case 98:
          status = '执行完成（有异常）'
          break

        case 99:
          status = '执行完成'
          break
      }
      return status
    },
    // 格式化时间
    formatterTime(row) {
      let timerows = parseTime(row.createTime)
      return timerows
    },
    SearchBtn() {
      this.search.page = 1
      this.currentPage = 1
      this.getTableInfo()
    },
    // 返回查询数据
    getTableInfo() {
      this.currentPage = this.search.page
      this.showLoading = true
      console.log('this.search:>>', this.search)
      searchAns(this.search)
        .then((res) => {
          // console.log('this.res:>>', res)
          let data = res.data
          this.rows = data.rows
          this.total = data.total
          this.showLoading = false
          this.$nextTick(() => {
            try {
              document.getElementById('searchbox').scrollTop = 0
            } catch (error) {
              console.log('error :>> ', error)
            }
          })
        })
        .catch((error) => {
          this.showLoading = false
        })
    },
    preViewDoc(id) {
      console.log('id:>>', id)
      // let url = `http://192.168.0.108:8806/preview_html?taskId=${id}`
      window.open(`/aisapi/preview_html?taskId=${id}`)
    },
    // 详情
    handleClick(row) {
      console.log('taskId:>>', row.taskId)
      const _this = this
      this.product = row.productCode
      if (row.productCode === '5') {
        if (location.pathname != '/pdf') {
          this.$router.push({
            name: 'PDF识别',
          })
        }
        if (row.status != '99') {
          _this.$message.warning('识别任务暂未完成，请稍后再试')
        } else {
          _this.preViewDoc(row.taskId)
        }
      } else {
        let path = ''
        let str = ''
        if (row.productCode === '1') {
          str = '表格识别'
          path = '/table'
        } else if (row.productCode === '6') {
          str = '文本比对'
          path = '/contractComparison'
        } else if (row.productCode === '7') {
          str = '舆情资讯分析'
          path = '/news'
        }
        if (location.pathname != path) {
          this.$router.push({
            name: str,
            params: {
              id: row.taskId,
            },
          })
        } else {
          if (row.productCode === '1') this.$root.$emit('tableID', row.taskId)
          else if (row.productCode === '6') this.$root.$emit('contractID', row.taskId)
          else if (row.productCode === '7') this.$root.$emit('newsID', row.taskId)
        }
      }
      this.$emit('show', false)
    },
    handleSizeChange(size) {
      // console.log('size :>> ', size)
      this.search.size = size
      this.getTableInfo()
    },
    handleCurrentChange(page) {
      // console.log('page :>> ', page)
      this.search.page = page
      this.getTableInfo()
    },
  },
}
</script>

<style scoped lang="stylus">
/deep/ .dialog .el-dialog__header
  display none !important

/deep/ .dialog .el-dialog__body
  padding 5px !important
  line-height 2

/deep/ .dialog
  padding 6px
  margin 0 !important
  position absolute
  top 137px
  right 20px

.message:hover
  right 20px
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/deep/ .el-collapse-item__header
  height 30px
  line-height 30px
  font-size 14px
/deep/ .el-pagination__jump
  margin 0
/deep/ .el-pagination__total
  margin 0
/deep/.table-head{
  background-color #f2f3f5 !important
}

.edit-item
  padding-bottom 16px

.el-form-item__label
  font-weight 700 !important

.container
  height 96%
.table-container
  color RGB(51,51,51)
  position relative
  padding 0 20px
  background-color white
  height calc(100%)
  .content
    position relative
    height calc(100% - 54px)
    .main
      height 100%
      margin: auto;
      .search
        margin-top 20px
        z-index 100
        position: fixed;
        position: sticky;
        top: 0px;
        display flex
        flex-direction row
        justify-content center
        align-items center
        flex-wrap wrap
        width 100%
        .el-button--small
          padding 9px 15px
        .el-button--primary
          border-radius 0 4px 4px 0
        .item-1
          flex-grow 1
        .search-item
          margin-right 0
          .label
            font-size 14px
          .input
            border-radius 0
      .search-wrapper
        z-index 99
        position: fixed;
        position: sticky;
        top: 0px;
        width 100%
        margin auto
      .table-wrapper
        height calc(100% - 32px)
        position relative
        overflow hidden
ul
  margin: 0;
  padding 0 1px
  border-bottom  1px solid #EBEEF5
  width: 100%
  overflow auto
li
  list-style: none
.searchbox
  width 96%
  line-height 1.5
  margin-top 8px
  margin-bottom 15px
  padding-bottom 15px
  border-bottom  1px dotted #eee
.date
  font-size 12px
  color brown
.taskName
  color #303133
  font-size 16px
  font-weight 700
  cursor pointer
.contenter
  cursor pointer
  color #606266
.fileName
  color #909399
.table
  position absolute
  top 0
  left 0
  background-color white
  width 100%
  height 100
.res
  display -webkit-box
  -webkit-box-orient vertical
  -webkit-line-clamp 3 // 控制显示三行
  overflow hidden
.select-item
  margin-bottom 12px
.body
  margin 8px 0
.pagination-wrapper
  position absolute
  left -6px
  bottom  0

/deep/ .el-collapse
    border 0
/deep/ .el-input__inner
    border-radius 4px 0 0 4px
/deep/ .el-collapse-item__arrow
    margin 0
/deep/ .el-form-item
    margin 0
/deep/ .el-collapse-item__content
    padding 0
</style>
