<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive :include="cachedViews"> -->
      <router-view :key="key" />
      <!-- </keep-alive> -->
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {}
  },
  computed: {
    key() {
      // console.log('this.$route.path :>> ', this.$route.path)
      // console.log('this.$router :>> ', this.$router)
      return this.$route.path
    },
    cachedViews() {
      let route = this.$store.state.permission.topbarRouters
      return route
    },
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="stylus" scoped>
.fade-transform-enter-active,
.fade-transform-leave-active {
  transition: opacity 0.4s;
}

.app-main {
  flex-grow 1
  width calc(100% - 40px)
  padding 16px 20px
  position relative
}

// .app-main::-webkit-scrollbar {
//   width: 6px !important
//   height: 6px !important
// }
// .app-main::-webkit-scrollbar-track {
//   // background-color: #3171c5 !important;
// }
// .app-main::-webkit-scrollbar-track-piece {
//   background-color: #a7b1bd3a !important;
//   border-radius: 3px;
// }
// .app-main::-webkit-scrollbar-thumb {
//   background-color: #3171c569 !important;
//   border-radius: 4px;
// }

// .fixed-header + .app-main {
//   padding-top: 50px;
// }

// .hasTagsView {
//   .app-main {
//     /* 84 = navbar + tags-view = 50 + 34 */
//     min-height: calc(100vh - 84px);
//   }

//   .fixed-header + .app-main {
//     padding-top: 84px;
//   }
// }
</style>

<style lang="stylus">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
