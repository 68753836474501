var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar",style:({
      color: `${_vm.currentAvatar.background}`,
      height: `${_vm.size}px`,
      'line-height': `${_vm.size}px`,
      width: `${_vm.size}px`,
      'font-size': `${_vm.size / 2.4}px`,
      'background-image': `url(${_vm.avatarUrl})`,
    })})
}
var staticRenderFns = []

export { render, staticRenderFns }