const state = {
  sheetData: {},
  sheetInfo: {},
  isEdit: false,
  isAutoClick: false,
  subTaskId: '',
  tableOrder: 0,
}

const mutations = {
  SHEET_DATA: (state, data) => {
    state.sheetData = data
  },
  IS_AUTOCLICK: (state, data) => {
    state.isAutoClick = data
  },
  IS_EDIT: (state, data) => {
    state.isEdit = data
  },
  SHEET_INFO: (state, data) => {
    state.sheetInfo = data
  },
  SUB_TASKID: (state, data) => {
    state.subTaskId = data
  },
  TABLE_ORDER: (state, data) => {
    state.tableOrder = data
  },
}

const actions = {
  isAutoClick({ commit }, data) {
    commit('IS_AUTOCLICK', data)
  },
  sheetData({ commit }, data) {
    commit('SHEET_DATA', data)
  },
  sheetInfo({ commit }, data) {
    commit('SHEET_INFO', data)
  },
  isEdit({ commit }, data) {
    commit('IS_EDIT', data)
  },
  subTaskId({ commit }, data) {
    commit('SUB_TASKID', data)
  },
  tableOrder({ commit }, data) {
    commit('TABLE_ORDER', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
