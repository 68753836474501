import request from '@/utils/request'

// 获取路由
export const getSettingInfo = () => {
  return request({
    url: '/listParam',
    params: {
      parentId: 1,
    },
    method: 'post',
  })
}
