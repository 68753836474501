<template>
  <div class="app-wrapper">
    <!-- 所有页面都有留言图标 -->
    <el-tooltip class="item" effect="dark" content="在线留言" placement="bottom">
      <div class="message" @click="toMessage"></div>
    </el-tooltip>
    <div class="app-head">
      <TopNav />
    </div>
    <div class="app-content">
      <div class="sidebar-container">
        <SidebarMain />
      </div>
      <div class="main-container">
        <TagsView v-if="showTagsView" />
        <AppMain />
      </div>
    </div>
  </div>
</template>

<script>
import variables from '@/assets/styles/base.module.styl'
import { SidebarMain, TagsView, AppMain, TopNav } from './components'
export default {
  name: 'LayoutContainer',
  components: {
    SidebarMain,
    TagsView,
    AppMain,
    TopNav,
  },
  data() {
    return {}
  },
  computed: {
    showTagsView() {
      return this.$store.state.app.showTagsView
    },
  },
  mounted() {},
  methods: {
    toMessage() {
      this.$router.push({ path: '/message' })
    },
  },
}
</script>

<style scoped lang="stylus">
.app-wrapper
  position: relative
  height: 100%
  width: 100%
  .app-head
    position relative
    height 59px
    background-color $base-background
    border-bottom 0.5px solid $color-border-3
    z-index 100
  .app-content
    // display none
    z-index 1
    position relative
    height calc(100% - 60px)
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    // background-color #234323
    .sidebar-container
      height 100%
      box-shadow 1px 1px 2px #e5e6eb
      flex-grow 0
      z-index 2
    .main-container
      z-index 1
      position relative
      overflow auto
      background-color #f2f3f5
      height 100%
      flex-grow 1
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-start

.main-container::-webkit-scrollbar {
  width: 6px !important
  height: 6px !important
}
.main-container::-webkit-scrollbar-track {
  // background-color: #3171c5 !important;
}
.main-container::-webkit-scrollbar-track-piece {
  background-color: #a7b1bd3a !important;
  border-radius: 3px;
}
.main-container::-webkit-scrollbar-thumb {
  background-color: #3171c569 !important;
  border-radius: 4px;
}
//留言图标样式
.message
  z-index 10
  position fixed
  right 10px
  bottom 8px
  height 50px
  width 50px
  background-image url("@/assets/images/home/Message_perspective_matte.svg")
  background-size 100%
  background-repeat no-repeat
  background-position center
  cursor pointer
  transition 1s
</style>
