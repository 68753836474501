<template>
  <div class="sidebarWrapper">
    <el-scrollbar class="sidebarScroll">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="
          settings.sideTheme === 'theme-light' ? variables.menuBackground : variables.dark_menuBackground
        "
        :text-color="settings.sideTheme === 'theme-light' ? variables.menuColor : variables.dark_menuColor"
        :unique-opened="true"
        :active-text-color="variables.menuColorActive"
        :collapse-transition="enableTransition"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        ></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarItem from './SidebarItem.vue'
import variables from '@/assets/styles/base.module.styl'
export default {
  name: 'SidebarMain',
  components: {
    SidebarItem,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['sidebarRouters', 'sidebar']),
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    enableTransition() {
      return !this.sidebar.withoutAnimation
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="stylus">
// didebar
.sidebarWrapper
  height calc(100% - 30px)
  padding 15px 8px
  border none !important
  position relative
.sidebarScroll
  height 100%
  background-color #fff
.scrollbar-wrapper
  overflow-x hidden !important
.el-scrollbar__bar.is-vertical
  right 0px;
/deep/.el-scrollbar__wrap
  height calc(100% +17px) !important

// 导航菜单
.el-menu
  width 204px;
  min-height 400px;
  border none !important
.el-menu--collapse
  width 50px
/deep/.el-menu-item, /deep/.el-submenu__title
  overflow hidden !important;
  text-overflow ellipsis !important;
  white-space nowrap !important;
  // margin-bottom 4px


/deep/.el-tooltip
  display flex !important
  flex-direction row
  justify-content flex-start
  align-items center
  padding-left 12px !important
  padding-right 12px !important
/deep/.el-menu-item
  // font-family MiSans-Light
  color $color-text-2
  height 40px !important
  padding-left 12px !important
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom 4px !important
// menu hover
/deep/.el-submenu .el-menu-item
  background-color $base-sub-menu-background !important
/deep/.el-submenu .el-menu-item:hover
  background-color $base-sub-menu-hover !important
  border-radius 4px
/deep/.el-submenu__title:hover
  background-color $base-menu-hover !important
  border-radius 4px
/deep/.el-menu-item:hover
  background-color $base-menu-hover !important
  border-radius 4px
</style>
