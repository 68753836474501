<template>
  <div>
    <BreadCrumb />
  </div>
</template>

<script>
import BreadCrumb from '@/components/Breadcrumb'
export default {
  name: 'TagsView',
  components: {
    BreadCrumb,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="stylus"></style>
