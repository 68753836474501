<template>
  <div
    class="avatar"
    :style="{
      color: `${currentAvatar.background}`,
      height: `${size}px`,
      'line-height': `${size}px`,
      width: `${size}px`,
      'font-size': `${size / 2.4}px`,
      'background-image': `url(${avatarUrl})`,
    }"
  ></div>
</template>

<script>
import avatarDefault from '@/assets/images/user/avatar.svg'
export default {
  name: 'AvatarView',
  props: {
    size: {
      type: Number,
      require: false,
      default: 100,
    },
    name: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      color: [
        {
          text: 'rgba(0, 184, 148, 0.25)',
          background: 'rgba(0, 184, 148, 1)',
        },
        {
          text: 'rgba(0, 206, 201, 0.25)',
          background: 'rgba(0, 206, 201, 1)',
        },
        {
          text: 'rgba(9, 132, 227, 0.25)',
          background: 'rgba(9, 132, 227, 1)',
        },
        {
          text: 'rgba(108, 92, 231, 0.25)',
          background: 'rgba(108, 92, 231, 1)',
        },
        {
          text: 'rgba(253, 203, 110, 0.25)',
          background: 'rgba(253, 203, 110, 1)',
        },
        {
          text: 'rgba(225, 112, 85, 0.25)',
          background: 'rgba(225, 112, 85, 1)',
        },
        {
          text: 'rgba(232, 67, 147, 0.25)',
          background: 'rgba(232, 67, 147, 1)',
        },
        {
          text: 'rgba(213, 67, 232, 0.25)',
          background: 'rgba(213, 67, 232, 1)',
        },
        {
          text: 'rgba(67, 232, 169, 0.25)',
          background: 'rgba(67, 232, 169, 1)',
        },
        {
          text: 'rgba(232, 229, 67, 0.25)',
          background: 'rgba(232, 229, 67, 1)',
        },
      ],
    }
  },
  computed: {
    currentAvatar() {
      let index = (this.name[0].charCodeAt() % 10) % 10
      return this.color[index]
    },
    avatarUrl() {
      let url = this.$store.state.user.avatar || avatarDefault
      // console.log('url :>> ', url)
      return url
    },
    text() {
      let text = this.name.split(' ')[0][0]
      return text.toUpperCase()
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="stylus">
.avatar
  border-radius 50%
  text-align center
  user-select none

  background-size 100%
  background-repeat no-repeat
  background-position center
</style>
