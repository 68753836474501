import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import permission from './modules/permission'
import settings from './modules/settings'
import app from './modules/app'
import table from './modules/table'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    user,
    permission,
    settings,
    app,
    table,
  },
})
