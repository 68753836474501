import request from '@/utils/request'
import qs from 'qs'

// 获取留言列表接口
export function listMessage({ page, size, type, status, name, endTime, startTime }) {
  let data = {
    page,
    size,
    type,
    status,
    name,
    startTime,
    endTime,
  }
  return request({
    url: '/ais/message/list_message',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 删除留言接口
export function deleteMessage({ id }) {
  let data = {
    id,
  }
  return request({
    url: '/ais/message/delete_message',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 保存留言接口
export function saveMessage({ id, topic, type, contents }) {
  let data = {
    id,
    topic,
    type,
    contents,
  }
  return request({
    url: '/ais/message/save_message',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 阅读留言接口
export function readMessage(id) {
  let data = {
    id,
  }
  return request({
    url: '/ais/message/read_message',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}
