import request from '@/utils/request'
import qs from 'qs'
import store from '@/store'

// 查询客户信息
export function queryCustomer() {
  // let data = {
  //   startTime,
  //   endTime,
  //   page,
  //   size,
  // }
  return request({
    url: '/ais/customer/query_customer',
    method: 'post',
    // data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 修改客户基本信息
export function editCustomer({ type, value }) {
  let data = {
    type,
    value,
  }
  return request({
    url: '/ais/customer/edit_customer',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 修改密码接口
export function editPassword({ newPassword }) {
  let data = {
    newPassword,
  }
  return request({
    url: '/ais/customer/edit_password',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 生产新的密钥对接口
export function genSecretKey() {
  return request({
    url: '/ais/customer/gen_secret_key',
    method: 'post',
    // data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}

// 上传头像
export function uploadAvatar(data) {
  store.dispatch('app/setLoaded', 0)
  return request({
    url: '/ais/customer/upload_avatar',
    method: 'post',
    data,
    headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
    onUploadProgress: function (progressEvent) {
      // 处理原生进度事件
      let percent = (progressEvent.loaded / progressEvent.total) * 100
      percent = Math.trunc(percent)
      store.dispatch('app/setLoaded', percent)
    },
  })
}
