import { login, logout, getInfo, socialLogin, socialLogin2, customLogin, weixinLogin } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Notification, MessageBox, Message } from 'element-ui'
import Cookies from 'js-cookie'
import './permission'

const user = {
  state: {
    token: getToken(),
    id: 0, // 用户编号
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    messageCount: 0,
    letterCount: 0,
  },

  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_MESSAGECOUNT: (state, count) => {
      state.messageCount = count
    },
    SET_LETTERCOUNT: (state, count) => {
      state.letterCount = count
    },
  },

  actions: {
    SetMessageCount({ commit }, count) {
      commit('SET_MESSAGECOUNT', count)
    },
    SetLetterCount({ commit }, count) {
      commit('SET_LETTERCOUNT', count)
    },
    setAvatar({ commit }, avatar) {
      commit('SET_AVATAR', avatar)
    },
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid)
          .then((res) => {
            res = res.data
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 自定义登陆
    CustomLogin({ commit }, userInfo) {
      const username = userInfo.username
      const password = userInfo.password
      const skey = userInfo.codeId
      const captcha = userInfo.code
      return new Promise((resolve, reject) => {
        customLogin(username, password, skey, captcha)
          .then((res) => {
            let message = res.request.responseURL.split('=')[1]
            if (message) {
              message = window.atob(message)
              message = decodeURIComponent(message)
              Message.error(message)
              console.error(message)
              reject(message)
            } else {
              let token = Cookies.get('token')
              // console.log('Cookies:>> ', token)
              setToken(token)
              commit('SET_TOKEN', token)
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 微信登录
    WeixinLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
        weixinLogin(data)
          .then((res) => {
            let token = res.headers['x-authorization']
            setToken(token)
            commit('SET_TOKEN', token)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 社交登录
    SocialLogin({ commit }, userInfo) {
      const code = userInfo.code
      const state = userInfo.state
      const type = userInfo.type
      return new Promise((resolve, reject) => {
        socialLogin(type, code, state)
          .then((res) => {
            res = res.data
            console.log('res :>> ', res)
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            resolve()
            console.log('res :>> ', res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 社交登录
    SocialLogin2({ commit }, userInfo) {
      const code = userInfo.code
      const state = userInfo.state
      const type = userInfo.type
      const username = userInfo.username.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        socialLogin2(type, code, state, username, password)
          .then((res) => {
            res = res.data
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            // 没有 data 数据，赋予个默认值
            if (!res) {
              res = {
                data: {
                  roles: [],
                  user: {
                    id: '',
                    avatar: '',
                    userName: '',
                  },
                },
              }
            }

            res = res.data // 读取 data 数据
            const user = res.user
            const avatar = user.avatar === '' ? require('@/assets/images/profile.jpg') : user.avatar
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_ID', user.id)
            commit('SET_NAME', user.userName)
            commit('SET_AVATAR', avatar)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_SIDEBAR_ROUTERS', [])
          removeToken()
          Cookies.remove('token')
          resolve('success')
        } catch (error) {
          reject(error)
        }
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
  },
}

export default user
