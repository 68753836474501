<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<svg-icon icon-class={icon} font-size="26" />)
    }

    if (title) {
      vnodes.push(
        <span style="margin-left: 15px" slot="title">
          {title}
        </span>
      )
    }
    return vnodes
  },
}
</script>
