import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import Cookies from 'js-cookie'
import { getSettingInfo } from '@/api/setting'

NProgress.configure({ showSpinner: false })

// 设置白名单，增加三方登陆
const whiteList = ['/login', '/wechat/callback', 'table']

// 获取界面设置信息
getSettingInfo().then((res) => {
  let info = res.data.rows.find((item) => item.code == 'show_company_info')
  console.log('info :>> ', info)
  store.dispatch('settings/changeSetting', {
    key: 'showCompanyInfo',
    value: info.value == '1' ? true : false,
  })
  console.log('this.$store.state.showCompanyInfo :>> ', store.state.settings.showCompanyInfo)
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  let Token = getToken()
  //
  if (Token && Token !== 'undefined') {
    // Sometimes getToken() will return 'undefined' (as a String value)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // next()
      // NProgress.done()

      if (store.getters.sidebarRouters.length === 0) {
        store
          .dispatch('GenerateRoutes')
          .then((res) => {
            let accessRoutes = res.rewriteRoutes
            let avatar = res.avatar
            // 添加路由
            router.addRoutes(accessRoutes)
            // 设置头像
            store.dispatch('setAvatar', avatar)
            next({ ...to, replace: true })
          })
          .catch((error) => {
            console.error('获取导航菜单失败 :>> ', error)
            removeToken()
            Cookies.remove('token')
            next(`/login`)
          })
      } else {
        next()
        NProgress.done()
      }
      // if (store.getters.roles.length === 0) {
      //   isRelogin.show = true
      //   // 获取字典数据 add by 芋艿
      //   store.dispatch('dict/loadDictDatas')
      //   // 判断当前用户是否已拉取完user_info信息
      //   store
      //     .dispatch('GetInfo')
      //     .then(() => {
      //       isRelogin.show = false
      //       store.dispatch('GenerateRoutes').then((accessRoutes) => {
      //         // 根据roles权限生成可访问的路由表
      //         router.addRoutes(accessRoutes) // 动态添加可访问路由表
      //         next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      //       })
      //     })
      //     .catch((err) => {
      //       store.dispatch('LogOut').then(() => {
      //         Message.error(err)
      //         next({ path: '/' })
      //       })
      //     })
      // } else {
      //   next()
      // }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
