import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from './utils/request'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/globle.css'
import VueContextMenu from 'vue-contextmenu'

// 图标
import '@/assets/icons'
// 权限控制
import './permission'
// mock数据
// import '@/mock/mockServer'

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.use(ElementUI)
Vue.use(VueContextMenu)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
