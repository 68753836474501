import request from '@/utils/request'
import qs from 'qs'
// 查询主任务列表接口
export function searchAns(data) {
  return request({
    url: '/ais/solr/search',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}
// 根据ID查询文本比对数据
export function searchContract(Id, productCode) {
  return request({
    url: '/ais/solr/show_task',
    method: 'post',
    params: { taskId: Id, productCode: productCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  })
}
